@-webkit-keyframes recent-update {
    from { background-color: $flash-update; }
    to { background-color: inherit; }
}

@-moz-keyframes recent-update {
    from { background-color: $flash-update; }
    to { background-color: inherit; }
}

@-o-keyframes recent-update {
    from { background-color: $flash-update; }
    to { background-color: inherit; }
}

@keyframes recent-update {
    from { background-color: $flash-update; }
    to { background-color: inherit; }
}

.recent-update {
    -webkit-animation: recent-update 1.5s; /* Safari 4+ */
    -moz-animation:    recent-update 1.5s; /* Fx 5+ */
    -o-animation:      recent-update 1.5s; /* Opera 12+ */
    animation:         recent-update 1.5s; /* IE 10+ */
    animation-delay:   0.5s;
}

.soft-deleted {
    background-color: $soft-deleted !important;
    color: $soft-deleted-text;
}

.odd.soft-deleted {
    background-color: $soft-deleted-odd !important;
    color: $soft-deleted-text-odd;
}

.h-overflow-scroll {
    overflow-x: auto;
}

.tr-clickable:hover {
    cursor: pointer;
}
