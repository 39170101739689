/*#main-customer-dropdown {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
}

#main-customer-dropdown:not(:focus) {
    border-color: rgba(0,0,0,.1);
}

#main-customer-dropdown.custom-select {
    background-image: none;
}

#main-customer-dropdown option {
    background: #fff;
    color: #000000;
}*/

.customer-select2 {
    width: 254.484px !important;
    height: 37.109px !important;
    color:white !important;
    background-color: rgb(39, 56, 73);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.customer-select2.select2-container--open {
    border: 1px solid rgb(54, 94, 134);
}

.customer-select2:focus {
    outline: 0 !important;
}

.customer-select2 span {
    //height: 34.109px !important;
    color:white !important;
    background-color: rgb(39, 56, 73);
    border: 0 !important;
}

.customer-select2 span:focus {
    outline: 0 !important;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

/*
// https://stackoverflow.com/a/30647614
.select-arrow {
    float: right;
    margin-top: -25px;
    margin-right: 5px;
    // this is so when you click on the chevron, your click actually goes on the dropdown menu
    pointer-events: none;
    // everything after this is just to cover up the original arrow
    // (for browsers that don't support the syntax used above)
    padding-right: 5px;
    color: #ffffff;
}
*/
