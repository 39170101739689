.price-input {
    padding: 0.25rem 0.4375rem;
    height: auto;
    //width: 80%;
}

.th-dark
{
    color: #fff;
    background-color: #696969;
    border-color: #5d5d5d;
}

select[readonly]{
    background: #eee;
    cursor:no-drop;
}

select[readonly] {
    pointer-events:none;
}

.thead-primary {
    @include gradient-bg;
}
