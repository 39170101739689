.show-card {
    border: 0px solid black;
}

.show-card-content {
    padding: 0rem;
}

.show-card-label {
    font-weight: bold;
    font-size: 1rem;
    padding: 0px;
    margin: 0px;
}

.show-newline {
    white-space: pre-line;
}
