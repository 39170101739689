.profile-page-link:hover
{
        background: linear-gradient(
250deg
, #2675b1, #3d445a);
}

.toggle-password:hover
{
    cursor: pointer;
}
