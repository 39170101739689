.orders-container {
    //border: 1px solid black;
    margin-bottom: 1.5rem;
    //padding: 0.75rem;
    padding-right: 12px;
}

.order-entry {
    //border: 1px solid black;
    //margin-bottom: 2rem;
    //padding: 0.5rem;
}

.order-details {
    border: 1px solid black;
    margin-bottom: 2rem;
    padding: 0.5rem;
}

.form-group .select2-container {
    width: 100% !important;
}

.order-details > .order-product {
    border: 1px solid black;
    margin-bottom: 2rem;
    padding: 0.5rem;
}

.quote-button-container {
    display: inline-flex;
}

//.select2-container--default .select2-selection--single {
//    border: 1px solid #e5e5e5 !important;
//}

.show-orders-container {
    //border-color: black;
    //border-style: solid;
    //border-width: 0px 1px 1px 1px;
    margin-bottom: 1rem;
}

.show-order-container {
    //border-top: 1px solid black;
    //padding-left: 1rem;
}

.show-order-item-container {
    //border-top: 1px solid black;
    //margin-left: -1rem;
    //padding-left: 1rem;
}

.product-type-extra-fields {
    //margin-bottom: 1.5rem;
}

#btn-add-order {
    margin-top: 1.5rem;
}

.btn-add-order-item {

}

.product-type-extra-fields {
    //padding-left: 0.5rem;
    //padding-right: 0.5rem;
    padding-bottom: 1.5rem;
    //background-color: rgb(231, 241, 248);
    //border: 1px solid black;
}

#quote-summary-panel {
    //margin-right: 12px;
}

#service-summary-panel {
    margin-right: 12px;
}

.service-summary-header {
    font-size: 18px !important;
}

.service-summary-alert {
    margin-bottom: 1rem;
}

.fixed {
    position: fixed;
    //width: 82.55%;
    //bottom:0;
}

.order-costs-container {
    margin-left: 0px;
    width: 100%;
}

.product-type-extra-fields {
    background-color: #e5eff5;
    //color: white;
    //background-image: -webkit-gradient(linear,right top, left top,from(rgba(46,182,151,.18)),to(transparent));
    //background-image: linear-gradient(270deg,rgba(46,182,151,.18), transparent);
    padding-bottom: 1rem;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
}

.product-type-extra-fields:nth-child(2) {
    background-color: #f2f7ff;
}

.main-order-items-title {
    @include gradient-bg;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.order-items-button-container
{
    margin-top: 8px;
}

.btn-remove-order-item {
    margin-top: 23.8px;
}

.order-header {
    @include gradient-bg;
}

.accordion.accordion-hover .order-header:not(.collapsed) {
    @include gradient-bg;
}

.accordion.accordion-hover .card-header:hover .order-header.collapsed {
    background-color: $nav-base-color;
    color: white;
    background-image: -webkit-linear-gradient(270deg, $nav-background-shade, transparent);
    background-image: linear-gradient(270deg, $nav-background-shade, transparent);
}

.accordion .card .card-header .order-header.collapsed {
    color: white;
}

#quote-summary-panel .panel-hdr {
    background-color: white;
}

.quote-summary-footer {
    position: fixed;
    bottom: 0;
    height: 67px;
    width: 100%;
    z-index: 100;
    @include gradient-bg;
}

.quote-summary-footer-content {
    height: 67px;
    width: calc(100% - 16.875rem);
    margin-left: 16.875rem;
}

.quote-sidebar-pad {
    width: 16.875rem;
    height: 67px;
    background-color: rgb(124, 188, 248);
    display: inline-block;
}

.quote-summary-total {
    font-size: 16px;
    color:white;
    font-weight: 500;
}

.quote-summary-pricing {
    font-size: 15px;
    color:white;
}

.quote-summary-button-row {
    padding-left: 20px;
}

.order-item-entry-title {
    padding-top: 0.5rem;
}

// Order show page things
.order-wizard {
    margin-bottom: 10px;
}

.order-wizard > .nav .nav-link.incomplete.done {
    color: #fff;
    border-color: #999;
    background: #999;
    cursor: pointer;
}

.order-wizard > .nav .nav-link.incomplete.done::after {
    border-left-color: #999;
}

.order-wizard > .nav .nav-link.complete.done {
    color: #fff;
    border-color: #5cb85c;
    background: #5cb85c;
    cursor: pointer;
}

.order-wizard > .nav .nav-link.complete.done::after {
    border-left-color: #5cb85c;
}

.order-wizard > .tab-content
{
    height: auto !important;
}
