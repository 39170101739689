.modal-preview {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.relation-container {
    padding-bottom: 1rem;
}
