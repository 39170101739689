.toast-center {
    max-width: 98%;
    width: 50%;
    left: 50%;
    transform: translate(-50%, 0px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    position: fixed;
    top: 0.25rem;
    background-color: rgba(255, 255, 255, 1);
}

.toast {
    display: block;
}
